#footer {
  display: flex;
  flex-direction: row;
  background-color: #212121;
  color: #efefef;
  padding-left: 20vh;
  padding-right: 20vh;
  padding-top: 3vh;
  padding-bottom: 5vh;
  font-size: 2vmin;
 }

 #footer a {
  color: #ffffff;
  text-decoration: underline;
}
 
#about {
  max-height: 100%;
  max-width: 51%;
  margin-right: 5vh;
}

#contact {
  max-height: 100%;
  max-width: 43%;
  margin-right: 3vh;
}

#layoutBody {
  margin-top: 2vh;
  margin-left: 20vh;
  margin-right: 20vh;
  margin-bottom: 5vh;
  width: 100%;
}

#header {
  width: 100%;
  background: url(../../assets/tgArt/halftone2.jpg);
  background-size: 67%;
  background-repeat: repeat;
  padding-top: 3vh;
  padding-bottom: 1.5vh;
  display: flex;
}

.text {
  border-bottom: solid transparent;
  margin-top: 1vh;
  margin-right: 2vh;
  color: white;
}

.active .text {
  border-bottom: solid #ffcebe;
  box-sizing: border-box; 
} 

#navLinks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: auto;
  font-family: 'Inconsolata', monospace;
  font-size: 2.8vmin;
  margin-bottom: 2vh;
  margin-top: 1vh;
  margin-right: 20vh;
}

#logoWrapper img {
  width: auto;
  max-height: 10vh;
  object-fit: scale-down;
  margin-bottom: 2vh;

}

#logoWrapper {
  /* justify-items: center; */
  width: 100%;
  margin-left: 20vh;

}

#layout {
  color: #2C2A2D;
  display: flex;
  flex-flow: wrap;
  width: auto;
  box-sizing: border-box;
} 

.sectionHeader {
  font-size: 2.9vmin;
  font-family: 'Karla', sans-serif;
  font-weight: bold;
}

#artists .displayGrid {
  display: grid;
  margin-top: 2vh;
  gap: 1vh;
  grid-template-columns: repeat(auto-fill, minmax(20vh, auto));
}

#loading {
  margin: 3vh;
}

.content {
  width: 100%;
}

/* CSS for Laptop */
@media only screen and (max-width: 1100px) {
  .sectionHeader {
    font-size: 3vmin;
  }

  #navLinks {
    margin-right: 2vh;
    width: 125%;
  }

  #logoWrapper {
    margin-left: 2vh;
  }

  #layoutBody {
    margin-left: 2vh;
    margin-right: 2vh;
    width: 100%;
  }

  #releases {
    width: auto;
  }

  #footer {
    width: 100%;
    padding-top: 5vh;
    padding-left: 2vh;
    padding-bottom: 5vh;
    padding-right: 2vh;
  }

}

/* CSS for tablets */
@media only screen and (max-width: 768px) {
  #navLinks {
    font-size: 3vmin;
    margin-right: 2vh;
    width: 70%;
  }

  .text {
    margin-right: 1.75vh; 
  } 
  .sectionHeader {
    font-size: 3vmin;
  }

  #footer {
    font-size: 2.25vmin;
  }
}

/* In between screen sizing */
/* @media only screen and (min-width: 602px) and (max-width: 700px) { */
/* @media only screen and (max-width: 700px) {
  .sectionheader {
    font-size: 8vmin;
  }
} */

/* Small laptop/desktop/tablet screen sizing */
@media only screen and (max-width: 601px) {
  .sectionHeader {
    font-size: 4vmin;
  } 

  #navLinks {
    font-size: 3.5vmin;
  }

  #homeLink {
    display: none;
  }

  #footer {
    font-size: 3vmin;
  }
}


/* CSS for mobile screens */
@media only screen and (max-width: 480px) {
  .sectionHeader {
    font-size: 5vmin;
  }
  #logoWrapper img {
    /* min-width: 8vh;
    max-width: 12vh; */
    margin-bottom: 0;
    margin-right: 1vh;
  }

  #navLinks {
    margin-right: 1vh;
    font-size: 3.75vmin;
    max-width: 70%;
    margin-bottom: 0;
  }

  .text {
    margin-right: 1vh;
  }


  #footer {
    display: flex;
    flex-direction: column;
    padding-top: 3vh;
    font-size: 4vmin;
 }
  #contact {
    min-width: 100%;
  }

  #about {
    min-width: 100%;
  }
}